export const useGlobalStore = defineStore('globalStore', {
  state: () => ({
    mobileMenu: false as Boolean,
    mobileSubMenues: false as Boolean,
  }),
  actions: {
    updateMobileMenu(state: Boolean) {
      this.mobileMenu = state
    },
    updateMobileSubMenues(state: Boolean) {
      this.mobileSubMenues = state
    },
  },
})
