<template>
    <svg  role="img" aria-label="beqom" class="beqom-logo w-auto h-full" width="137" height="40" viewBox="0 0 137 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <title>beqom</title>
        <path d="M90.1882 8.51953C83.0278 8.51953 78.5249 14.0215 78.5249 20C78.5249 25.9786 83.0278 31.4806 90.1882 31.4806C97.3491 31.4806 101.874 25.9786 101.874 20C101.874 14.0215 97.3491 8.51953 90.1882 8.51953ZM90.1991 27.9034C85.4639 27.9034 82.5513 24.0514 82.5513 20.0001C82.5513 15.9488 85.4639 12.0968 90.1991 12.0968C94.9572 12.0968 97.8474 15.9488 97.8474 20.0001C97.8474 24.0514 94.9572 27.9034 90.1991 27.9034Z" fill="#C30014"/>
        <path class="beqom-logo__lighter" d="M105.189 30.9615V17.0975C105.189 11.9854 108.725 8.51953 113.755 8.51953C116.787 8.51953 119.247 9.75424 120.828 11.7688C122.41 9.75424 124.913 8.51953 127.923 8.51953C132.953 8.51953 136.467 11.9854 136.467 17.0975V30.9615H132.514V17.769C132.514 14.3898 131.042 12.2237 127.659 12.2237C124.277 12.2237 122.805 14.3898 122.805 17.769V30.9615H118.851V17.769C118.851 14.3898 117.38 12.2237 113.997 12.2237C110.614 12.2237 109.143 14.3898 109.143 17.769V30.9615H105.189Z" fill="#C30014"/>
        <path class="beqom-logo__lighter" d="M63.6565 8.51953C71.0592 8.51953 75.342 14.0649 75.342 19.9784V39.4954H71.3887V27.6683C69.829 29.8127 66.8858 31.4807 63.3053 31.4807C56.5837 31.4807 51.9932 26.1736 51.9932 19.9784C51.9932 13.5883 56.7374 8.51953 63.6565 8.51953ZM63.6565 12.267C59.1098 12.267 56.1006 15.8628 56.1006 19.9784C56.1006 24.2241 59.1979 27.7333 63.6565 27.7333C68.3574 27.7333 71.2129 24.0075 71.2129 19.9784C71.2129 15.9928 68.3574 12.267 63.6565 12.267Z" fill="#C30014"/>
        <path class="beqom-logo__lighter" d="M38.3276 31.4807C31.5403 31.4807 26.5322 26.9751 26.5322 19.9568C26.5322 13.545 31.0791 8.51953 37.8883 8.51953C44.5218 8.51953 48.9147 13.61 48.9147 19.5452C48.9147 20.3034 48.9147 20.8016 48.8709 21.1915H30.5739C31.101 25.3938 34.3299 27.8632 38.3935 27.8632C42.1056 27.8632 43.8409 26.2603 45.4662 24.5707L48.2558 27.0184C46.1035 29.4879 42.9842 31.4807 38.3276 31.4807ZM37.8883 12.137C34.1102 12.137 31.5403 14.4548 30.7715 17.7473H44.4778C43.9288 14.6714 41.6444 12.137 37.8883 12.137Z" fill="#C30014"/>
        <path class="beqom-logo__lighter" d="M11.6636 31.4807C4.28323 31.4807 0 25.9354 0 20.0218V0.504883H3.95375V12.332C5.51328 10.1875 8.45664 8.51961 12.037 8.51961C19 8.51961 23.3491 14.065 23.3491 20.0218C23.3491 25.9354 19.0659 31.4807 11.6636 31.4807ZM11.6636 27.7333C16.3641 27.7333 19.2196 24.0076 19.2196 20.0218C19.2196 15.9928 16.3641 12.2671 11.6636 12.2671C6.98496 12.2671 4.10751 15.9928 4.10751 20.0218C4.10751 24.0076 6.98496 27.7333 11.6636 27.7333Z" fill="#C30014"/>
        <path d="M93.6153 15.7354C94.5647 15.7354 94.8938 16.4154 94.8938 17.2532C94.8938 18.0912 94.5647 18.7699 93.6153 18.7699C92.6658 18.7699 92.3356 18.0912 92.3356 17.2532C92.3356 16.4154 92.6658 15.7354 93.6153 15.7354ZM88.3593 15.7354C89.3088 15.7354 89.6378 16.4154 89.6378 17.2532C89.6378 18.0912 89.3088 18.7699 88.3593 18.7699C87.4099 18.7699 87.0796 18.0912 87.0796 17.2532C87.0796 16.4154 87.4099 15.7354 88.3593 15.7354ZM95.5092 21.3836C95.1387 23.4482 93.3051 25.0167 91.0973 25.0167C88.8893 25.0167 87.0557 23.4482 86.6851 21.3836C86.6137 20.9826 86.9854 20.6679 87.3949 20.7271C88.623 20.9048 89.8601 20.9937 91.0973 20.9937C92.3343 20.9937 93.5713 20.9048 94.7996 20.7271C95.209 20.6679 95.5808 20.9826 95.5092 21.3836Z" fill="#C30014"/>
    </svg>
        
</template>